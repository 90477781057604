<template lang="html">
    <div class="container inputOutput">
        <top-navigation-bar
            title="Two Proportions"
            right-nav="reset"
            @click="doReset"
        />
        <div class="m-view mt-3">
            <h2>H<sub>0</sub> : p<sub>1</sub> - p<sub>2</sub> = 0</h2>
        </div>
        <div class="m-view d-flex mb-4">
            <h2>H<sub>A</sub> : p<sub>1</sub> - p<sub>2</sub> {{ getSign }} 0</h2>
        </div>
        <div class="inline-input-view multi d-flex">
            <input-single
                v-model="n1"
                label="𝒏₁"
                style-class="input-single group"
                group
            />
            <input-single
                v-model="n2"
                label="𝒏₂"
                style-class="input-single group"
                group
            />
        </div>
        <div class="inline-input-view multi d-flex">
            <input-single
                v-model="x̄1"
                label="𝒙₁"
                style-class="input-single group"
                group
            />
            <input-single
                v-model="x̄2"
                label="𝒙₂"
                style-class="input-single group"
                group
            />
        </div>
        <div class="sticky-bottom m-3 mt-5">
            <button
                class="action-btn btn-block"
                block
                @click="onSubmit()"
            >
                Calculate
            </button>
        </div>

        <modal
            ref="modalOutput"
            title="Two Proportions - Result"
            modal-type="hypothesis"
        >
            <template #formula>
                <div class="m-view">
                    <h2>H<sub>0</sub> : p<sub>1</sub> - p<sub>2</sub> = 0</h2>
                </div>
                <div class="m-view d-flex mb-2">
                    <h2>H<sub>A</sub> : p<sub>1</sub> - p<sub>2</sub> {{ getSign }} 0</h2>
                </div>
            </template>

            <template #image>
                <div class="m-view d-flex justify-content-start">
                    <img
                        class="formulaImage-lg"
                        :src="require(`@/assets/HTformulaImgs/${getComputationName}.jpg`)"
                    >
                    <h3 class="formulaImage-text">
                        = {{ z }}
                    </h3>
                </div>
            </template>
            <template #graph>
                <h4 class="mb-3 text-center">
                    p-Value = {{ notEqualPdisplay }}P(z {{ symbolPdisplay }} {{ z }}) = {{ prob }}
                </h4>
                <highcharts
                    class="m-auto"
                    :options="hypothGraph.chartOptions"
                />
            </template>
            <template #conclusion>
                <h3 class="mb-3 text-center">
                    Conclusion:
                </h3>
                <h4 class="mb-3 text-center">
                    There is a {{ probDisplay }}% chance of concluding <br> p<sub>1</sub> - p<sub>2</sub> {{ getSign }} 0 and being wrong
                </h4>
            </template>
        </modal>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import localStore from '@/store/localStore.js'
import InputSingle from '@/components/input-single.vue'
import Modal from '@/components/modal.vue'
import normalData from '@/js/distributions/normal-data.js'
import hypothesisData from '@/js/hypothesis/hypothesis-data.js'
import { clearData } from '@/js/lib/input-check.js'
import { Chart } from 'highcharts-vue'
import _ from 'lodash'

export default {
    name: 'TwoProportionInput',
    components: {
        TopNavigationBar,
        highcharts: Chart,
        InputSingle,
        Modal
    },
    data () {
        return {
            allData: {},
            x̄1: '',
            x̄2: '',
            n1: '',
            n2: '',
            z: '',
            prob: '',
            graphData: [],
            choiceData: [], // Local store
        }
    },
    computed: {
        notEqualPdisplay () {
            const output = this.getSign === '≠' ? '2' : ''
            return output
        },
        symbolPdisplay () {
            var value = this.getSign
            if (value !== '≠') {
                return value
            } else {
                const symbol = this.z >= 0 ? '>' : '<'
                return symbol
            }
        },
        probDisplay () {
            var prob = _.get(this, 'prob', '')
            const output = Number((prob * 100).toFixed(2))
            return output
        },
        getSign () {
            return _.get(this.choiceData.selected, 'value', '')
        },
        getComputationName () {
            return _.get(this.choiceData, 'computationPicked', '')
        },
        hypothGraph () {
            return hypothesisData.graph(this.graphData)
        },
    },
    beforeMount () {
        var storeChoice = localStore.getStore('hypothesis-data-choice-twoP').choice
        var store = localStore.getStore('hypothesis-data') || ''
        if (storeChoice) {
            this.choiceData = storeChoice
            _.assign(this, { allData: store })
            var formulaStore = _.get(store, `${storeChoice.computationPicked}`, '')
            if (formulaStore) {
                _.assign(this, formulaStore.input)
            }
        }
    },
    methods: {
        doReset () {
            _.assign(this, clearData(this, ['x̄1', 'x̄2', 'n1', 'n2']))
        },
        showModal () {
            this.$refs.modalOutput.show()
        },
        onSubmit () {
            var { allData } = this
            const output = hypothesisData.calculateTwoProportion(this)
            if (!output) {
                console.log('Calculation Error')
            } else {
                _.assign(this, output.data)
                const graphOutput = normalData.calculateGraphData(this, 0, 1)
                _.set(this, 'graphData', graphOutput)

                _.set(allData, `${this.getComputationName}`, { input: output.input, output: output.data })
                localStore.setStoreItem('hypothesis-data', allData)
                this.showModal()
            }
        },
    },
}
</script>
